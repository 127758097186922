

import {Link, Trans, useTranslation, useI18next} from 'gatsby-plugin-react-i18next';



import React from "react"
import i18next from '../i18n/config';
// import ImgHeader from "../img/A_glowna_1/tlo_glowna.jpg";


import IconSearch from "../img/O_jakość_22/szukamy_kawy_iko.png";
import IconPlants from "../img/O_jakość_22/plantacje_iko.png";
import IconLab from "../img/O_jakość_22/lab_iko.png";
import IconCert from "../img/O_jakość_22/certyfikaty_iko.png";
import IconOrigin from "../img/O_jakość_22/single_iko.png";
import IconBio from "../img/O_jakość_22/bio_iko.png";

import PlantMap from "../img/O_jakość_22/plantacje_mapa.png";
import CertMap from "../img/O_jakość_22/logo_jakosc_2.png";



export default function Header(props) {



    return (
            <React.Fragment>


                <section class="quality firstSection">
                    <div class="kebab">
                        <h1>{i18next.t('QualityH1')}</h1>
                        <p>{i18next.t('QualityP')}</p>
                        <div class="features">
                            <div class="feature" onClick={() => document.scrolltosection("source")}  >
                                <a>
                                    <div>  <img loading="lazy" loading="lazy" src={IconSearch} alt="Instanta Search Coffee Icon" /></div>
                                    <p>{i18next.t('QualityFeature1')}</p>
                                </a>
                            </div>
                            <div class="feature" onClick={() => document.scrolltosection("buy")} >
                                <a>
                                    <div><img loading="lazy" loading="lazy" src={IconPlants} alt="Instanta Coffee Plantations Icon" /></div>
                                    <p>{i18next.t('QualityFeature2')}</p>
                                </a>
                            </div>
                            <div class="feature" onClick={() => document.scrolltosection("maintain")} >
                                <a>
                                    <div><img loading="lazy" loading="lazy" src={IconLab} alt="Instanta Lab Icon" /></div>
                                    <p>{i18next.t('QualityFeature3')}</p>
                                </a>
                            </div>
                            <div class="feature" onClick={() => document.scrolltosection("certificates")} >
                                <a>
                                    <div><img loading="lazy" loading="lazy" src={IconCert} alt="Instanta Certifications Icon" /></div>
                                    <p>{i18next.t('QualityFeature4')}</p>
                                </a>
                            </div>
                            <div class="feature" onClick={() => document.scrolltosection("origin")} >
                                <a>
                                    <div><img loading="lazy" loading="lazy" src={IconOrigin} alt="Instanta Single Origin Coffee Icon" /></div>
                                    <p>{i18next.t('QualityFeature5')}</p>
                                </a>
                            </div>
                            <div class="feature" onClick={() => document.scrolltosection("bio")} >
                                <a>
                                    <div><img loading="lazy" loading="lazy" src={IconBio} alt="Instanta Bio Organic Eco Coffee Icon" /></div>
                                    <p>{i18next.t('QualityFeature6')}</p>
                                </a>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="source" class="source">
                    <div class="sourcebg">
                        <h2>{i18next.t('QualitySourceH2')}</h2>
                        <p>{i18next.t('QualitySourceP')}</p>
                        <div class="link">
                            <Link to={'/OurCoffees/'}> <p>{i18next.t('CoffeeBeansMiniMenu1')}</p>
                            </Link>
                        </div>
                    </div>
                </section>

                <section id="buy" class="buy">
                    <div class="txt">
                        <h2>{i18next.t('QualityBuyH2')}</h2>
                        <p>{i18next.t('QualityBuyP')}</p>
                        <div class="link">
                            <Link to={'/OurCoffees/'}> <p>{i18next.t('CoffeeBeansMiniMenu1')}</p>
                            </Link>
                        </div>
                    </div>
                    <div class="pic">
                        <img loading="lazy" loading="lazy" src={PlantMap} alt="Instanta Coffee Plantations Map" />
                    </div>
                </section>

                <section id="maintain" class="maintain">
                    <div class="maintainbg">
                        <h2>{i18next.t('QualityMaintainH2')}</h2>
                        <p>{i18next.t('QualityMaintainP')}</p>
                        <div class="link">
                            <Link to={'/OurCoffees/'}> <p>{i18next.t('CoffeeBeansMiniMenu1')}</p>
                            </Link>
                        </div>
                    </div>
                </section>

                <section id="certificates" class="certificates">
                    <div class="desc">
                        <h2>{i18next.t('QualityCertH2')}</h2>
                        <p>{i18next.t('QualityCertP')}</p>
                        <div class="link">
                            <Link to={'/OurCoffees/'}> <p>{i18next.t('CoffeeBeansMiniMenu1')}</p>
                            </Link>
                        </div>
                    </div>
                    <div class="pic">
                        <img loading="lazy" loading="lazy" src={CertMap} alt="Instanta Certificates Maps" />
                    </div>
                </section>

                <section id="origin" class="origin">
                    <div class="originbg">
                        <h2>{i18next.t('QualityOriginH2')}</h2>
                        <p>{i18next.t('QualityOriginP')}</p>
                        <div class="link">
                            <Link to={'/OurCoffees/'}> <p>{i18next.t('CoffeeBeansMiniMenu1')}</p>
                            </Link>
                        </div>
                    </div>
                </section>

                <section id="bio" class="bio">
                    <div class="biobg">
                        <h2>{i18next.t('QualityBioH2')}</h2>
                        <p>{i18next.t('QualityBioP')}</p>
                        <div class="link">
                            <Link to={'/OurCoffees/'}> <p>{i18next.t('CoffeeBeansMiniMenu1')}</p>
                            </Link>
                        </div>
                    </div>
                </section>





            </React.Fragment>

            )


}
